<template>
  <v-container
    fluid
    class="fill-height"
  >
    <v-row
      justify="center"
      align="center"
      class="ma-0"
    >
      <empty-state
        color="error"
        class="text--white"
      >
        <template v-slot:icon>
          mdi-alert
        </template>
        <template v-slot:title>
          Ocorreu um erro com o seu pedido
        </template>
        <template v-slot:description>
          Por favor, tente novamente mais tarde
        </template>
      </empty-state>
      <v-btn @click="redirectLogin" depressed>
        Ir para o login
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
  import EmptyState from "../components/Helpers/EmptyState"
  import {PLATFORM_URL} from "../globals"

  export default {
    name: "Error",
    components: {EmptyState},
    methods: {
      redirectLogin() {
        window.location.replace(`${PLATFORM_URL}/login`)
      }
    }
  }
</script>

<style scoped>

</style>