var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "fill-height", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0", attrs: { justify: "center", align: "center" } },
        [
          _c("empty-state", {
            staticClass: "text--white",
            attrs: { color: "error" },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function() {
                  return [_vm._v(" mdi-alert ")]
                },
                proxy: true
              },
              {
                key: "title",
                fn: function() {
                  return [_vm._v(" Ocorreu um erro com o seu pedido ")]
                },
                proxy: true
              },
              {
                key: "description",
                fn: function() {
                  return [_vm._v(" Por favor, tente novamente mais tarde ")]
                },
                proxy: true
              }
            ])
          }),
          _c(
            "v-btn",
            { attrs: { depressed: "" }, on: { click: _vm.redirectLogin } },
            [_vm._v(" Ir para o login ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }